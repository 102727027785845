import React from 'react'
import SALink from 'components/SALink'
import FeaturedStoriesConnector, {
  type ConnectorProps as FeaturedStoriesProps,
} from '../../connectors/FeaturedStories'
import { SATypography } from 'components/SATypography/SATypography'
import { SATypographyVariant } from 'components/SATypography/SATypography.model'
import useScreenSize from 'hooks/useScreenSize'

const FeaturedStories = ({
  featuredStories,
}: FeaturedStoriesProps): React.ReactElement<React.ComponentProps<any>, any> => {
  const { isMobile } = useScreenSize()

  return (
    <div data-type='featured-stories'>
      <div data-type='featured-stories-header'>
        <SATypography data-type='stories-header' variant={SATypographyVariant.H3}>
          More to Explore
        </SATypography>
        <SALink href='/stories'>
          <SATypography data-type='view-all' variant={isMobile ? SATypographyVariant.CTA_SMALL : SATypographyVariant.CTA_MEDIUM}>
            View All
          </SATypography>
        </SALink>
      </div>
      <div data-section='stories'>
        <div data-type='scroll'>
          {featuredStories.map((tileData, key) => {
            const { linkText, linkUrl, thumbnailImageUrl, title } = tileData
            return (
              <div data-type='story' key={key}>
                <SALink href={linkUrl} title={title}>
                  <img
                    className='lazyload'
                    data-src={thumbnailImageUrl}
                    alt={title}
                    width='400'
                    height='228'
                    loading='lazy'
                  />
                </SALink>

                <div data-type='featuredStoriesStoryInfo'>
                  <SATypography data-type='story-title' variant={SATypographyVariant.H6}>
                    {title}
                  </SATypography>
                  <SALink href={linkUrl} alt={`${title} Collection`}>
                    <SATypography data-type='story-description' variant={isMobile ? SATypographyVariant.CTA_SMALL : SATypographyVariant.CTA_MEDIUM}>
                      {linkText}
                    </SATypography>
                  </SALink>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default FeaturedStoriesConnector(FeaturedStories)