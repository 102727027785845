import React from 'react'
import SALink from 'components/SALink'
import { convertLocaleToDefaultCurrency } from 'components/FormatPrice/helpers'
import type { ConnectorProps as LocaleConnectorProps } from 'connectors/Locale'
import LocaleConnector from 'connectors/Locale'
import LocaleCurrencySymbol from 'components/FormatPrice/LocaleCurrencySymbol'
import prices from './data'
import { SATypography } from 'components/SATypography/SATypography'
import { SATypographyVariant } from 'components/SATypography/SATypography.model'
import useScreenSize from 'hooks/useScreenSize'

export default LocaleConnector(
  (props: LocaleConnectorProps): React.ReactElement<React.ComponentProps<any>, any> => {
    const { selectedCurrency } = props
    const { isMobile } = useScreenSize()

    return (
      <div data-type='shop-by-price-container'>
        <div data-type='shop-by-price'>
          <SATypography data-type='shop-price-title' variant={SATypographyVariant.H3}>
            Shop by Price
          </SATypography>
          <div data-type='shop-row'>
            {prices.map((price, index) => {
              const { min, max } = price
              const minPrice = min > 0 ? convertLocaleToDefaultCurrency(min, selectedCurrency) : min
              const maxPrice = convertLocaleToDefaultCurrency(max, selectedCurrency)

              return (
                <SALink href={`/all?price=${minPrice}-${maxPrice}`} key={index} data-type='price-cta'>
                  {/* If min is > 0, show the price with currency symbol. Otherwise show 'UNDER'. */}
                  {min > 0 ? (
                    <SATypography variant={isMobile ? SATypographyVariant.CTA_SMALL : SATypographyVariant.CTA_MEDIUM}>
                      <LocaleCurrencySymbol {...{ selectedCurrency }} />
                      {min} -
                    </SATypography>
                  ) : (
                    <SATypography variant={isMobile ? SATypographyVariant.CTA_SMALL : SATypographyVariant.CTA_MEDIUM}>UNDER</SATypography>
                  )}
                  <SATypography variant={isMobile ? SATypographyVariant.CTA_SMALL : SATypographyVariant.CTA_MEDIUM} style={{ paddingLeft: '5px' }}>
                    <LocaleCurrencySymbol {...{ selectedCurrency }} />
                    {max}
                  </SATypography>
                </SALink>
              )
            })}
          </div>
        </div>
      </div>
    )
  }
)