import React from 'react'
import TrustPilotWidget from 'components/TrustPilot/Widget'
import HR from 'components/HR'
import Hero from '../components/Hero'
import TopTiles from '../components/TopTiles'
import CarouselSmallTop from '../components/Carousels/CarouselSmallTop'
import CarouselSmallBottom from '../components/Carousels/CarouselSmallBottom'
import Carousel from '../components/Carousel'
import ArtAdvisoryCallout from '../components/ArtAdvisoryCallout'
import FeaturedStories from '../components/FeaturedStories'
import ShopByPrice from '../components/ShopByPrice'
import ShopByCategory from '../components/ShopByCategory'
import ValuePropsMobile from '../components/ValueProps/Mobile'
import { NarrowWrapper, Separator, TrustPilotWrapper, Wrapper } from '../components/styles'

const HomepageLayout = (): React.ReactElement<React.ComponentProps<any>, any> => (
  <Wrapper>
    <Hero />

    <NarrowWrapper>
      <TopTiles />
    </NarrowWrapper>

    <NarrowWrapper>
      <ShopByCategory />
    </NarrowWrapper>

    <NarrowWrapper>
      <Carousel />
    </NarrowWrapper>

    <NarrowWrapper>
      <div data-type='carousel-small-container'>
        <CarouselSmallTop />
        <CarouselSmallBottom />
      </div>
    </NarrowWrapper>

    <NarrowWrapper>
      <ShopByPrice />
    </NarrowWrapper>

    <ArtAdvisoryCallout />

    <NarrowWrapper>
      <FeaturedStories />
    </NarrowWrapper>

    <Separator />

    <ValuePropsMobile />

    <Separator />

    <TrustPilotWrapper>
      <TrustPilotWidget />
    </TrustPilotWrapper>
  </Wrapper>
)

export default HomepageLayout