import React from 'react'
import SALink from 'components/SALink'
import { LocaleCurrency } from 'components/FormatPrice'
import { ArtworkItem, ArtworkInfo } from './styles'
import { SATypography } from 'components/SATypography/SATypography'
import { SATypographyVariant } from 'components/SATypography/SATypography.model'

const Artwork = (
  props: Record<string, any>
): React.ReactElement<React.ComponentProps<any>, any> => {
  const {
    artistName,
    artistProfileUrl,
    artworkTitle,
    artworkUrl,
    listPrice,
    thumbnailImageBase,
  } = props
  return (
    <ArtworkItem>
      <SALink href={`${artworkUrl}`} title={artworkTitle}>
        <picture data-type='image'>
          <img
            className='lazyload'
            loading='lazy'
            width='207'
            height='207'
            data-src={`${thumbnailImageBase}-25.jpg`}
            alt={`${artworkTitle}`}
            itemProp='image'
          />
        </picture>
        <noscript>
          <img
            width='207'
            height='207'
            src={`${thumbnailImageBase}-25.jpg`}
            alt={artworkTitle}
            itemProp='image'
          />
        </noscript>
      </SALink>

      <ArtworkInfo>
        <SALink data-type='artworkTitle' href={artworkUrl} alt={artworkTitle}>
          <SATypography capitalize truncateText variant={SATypographyVariant.H6}>
            {artworkTitle}
          </SATypography>
        </SALink>
        <SALink data-type='artistName' href={artistProfileUrl} alt={`${artistName} Profile`}>
          <SATypography truncateText variant={SATypographyVariant.X_SMALL}>
            {artistName}
          </SATypography>
        </SALink>
        <SATypography data-type='artworkPrice' variant={SATypographyVariant.X_SMALL}>
          {/* Show price if available */}
          {listPrice ? <LocaleCurrency price={listPrice / 100} /> : ''}
        </SATypography>
      </ArtworkInfo>
    </ArtworkItem>
  )
}

export default Artwork
