import React from 'react'
import SALink from 'components/SALink'
import { SATypography } from 'components/SATypography/SATypography'
import { SATypographyVariant } from 'components/SATypography/SATypography.model'
import useScreenSize from 'hooks/useScreenSize'

const CATEGORIES = [
  {
    categoryName: 'Paintings',
    categoryUrl: 'paintings',
  },
  {
    categoryName: 'Photography',
    categoryUrl: 'photography',
  },
  {
    categoryName: 'Fine Art Prints',
    categoryUrl: 'prints',
  },
  {
    categoryName: 'Sculpture',
    categoryUrl: 'sculpture',
  },
]

const ShopByCategory = (): React.ReactElement<React.ComponentProps<any>, any> => {
  const { isMobile } = useScreenSize()

  return (
    <div data-type='shop-by-category-container'>
      <div data-type='shop-by-category'>
        <SATypography data-type='shop-category-title' variant={SATypographyVariant.H3}>
          Shop by Category
        </SATypography>
        <div data-type='shop-row'>
          {CATEGORIES.map((category, index) => {
            const { categoryName, categoryUrl } = category
            return (
              <SALink href={`/${categoryUrl}`} key={index} data-type='category-cta'>
                <SATypography variant={isMobile ? SATypographyVariant.CTA_SMALL : SATypographyVariant.CTA_MEDIUM}>{categoryName}</SATypography>
              </SALink>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default ShopByCategory
