import { connect } from 'react-redux'
import { ReduxState } from 'reducers/index'
import { createStructuredSelector } from 'reselect'
import { GeoLocation, GeoLocationMetaData } from 'selectors/geoLocation'
import { getGeoLocation, getGeoLocationMetaData } from 'selectors/geoLocation'

const mapStateToProps: (state: ReduxState, params: any[]) => any = createStructuredSelector({
  geoLocation: getGeoLocation,
  geoLocationMetaData: getGeoLocationMetaData,
})

const mapDispatchToProps = (dispatch: (...args: Array<any>) => any) => ({})

export type ConnectorProps = {
  geoLocation: GeoLocation
  getGeoLocationMetaData: GeoLocationMetaData
}
const connector = connect(mapStateToProps, mapDispatchToProps)
export default connector
