import React from 'react'
import SALink from 'components/SALink'
import ArtworkItem from './CarouselSmall/Artwork'
import CarouselConnector, {
  type ConnectorProps as CarouselConnectorProps,
} from '../../connectors/Carousels'
import { SATypography } from 'components/SATypography/SATypography'
import { SATypographyVariant } from 'components/SATypography/SATypography.model'
import useScreenSize from 'hooks/useScreenSize'

const CarouselSmallTop = (
  props: CarouselConnectorProps
): React.ReactElement<React.ComponentProps<any>, any> | null | undefined => {
  const { carouselDataMultiple } = props
  const { isMobile } = useScreenSize()

  if (carouselDataMultiple.length < 1) {
    return null
  }

  const { artworks, collectionUrl, linkText, title } = carouselDataMultiple[0]

  return (
    <div data-type='small-carousel'>
      <div data-type='carousel-header'>
        <SATypography data-type='section-title' variant={SATypographyVariant.H3}>
          {title}
        </SATypography>
        <div>
          <SALink href={collectionUrl}>
            <SATypography data-type='section-link' variant={isMobile ? SATypographyVariant.CTA_SMALL : SATypographyVariant.CTA_MEDIUM}>
              {linkText}
            </SATypography>
          </SALink>
        </div>
      </div>
      <div data-type='products'>
        <div data-type='scroll'>
          {artworks.map((artworkData, key) => (
            <ArtworkItem
              key={key}
              {...{
                collectionUrl,
                ...artworkData,
              }}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default CarouselConnector(CarouselSmallTop)