import React from 'react'
import SALink from 'components/SALink'
import { useCarouselSwipe } from 'hooks/carousel'
import Carousel from 'components/Carousel/Hero'
import { SplitArray } from '../../helpers'
import type { ConnectorProps as CarouselConnectorProps } from '../../connectors/LargeCarousel'
import LargeCarouselConnector from '../../connectors/LargeCarousel'
import { Dots } from '../styles'
// import { Collections } from './data'
import { SATypography } from 'components/SATypography/SATypography'
import { SATypographyVariant } from 'components/SATypography/SATypography.model'
import useScreenSize from 'hooks/useScreenSize'

// import { Collections } from './data'
const CarouselComponent = (
  props: CarouselConnectorProps
): React.ReactElement<React.ComponentProps<any>, any> => {
  const { largeCarouselItems, screenWidth } = props
  const { isMobile } = useScreenSize()
  // const { screenWidth } = props
  // To facilitate required data behavior, we take the single array from
  // the feed, which is used as-is for mobile and split it into two arrays
  // for desktop.
  const [leftArray, rightArray] = SplitArray(largeCarouselItems)
  const List = screenWidth < 1200 ? largeCarouselItems : leftArray
  const carouselName = 'productCarousel'
  const { index, next, prev, productIndex, setIndex, setSwipeIndex, swipeIndex } = useCarouselSwipe(
    List.length,
    screenWidth,
    carouselName
  )
  const mobileBreakpoint = 780
  const isOnMobile = screenWidth <= mobileBreakpoint
  const safeSetIndex = isOnMobile ? setSwipeIndex : setIndex
  const safeIndex = isOnMobile ? swipeIndex : index
  const mobileScreenWidth = screenWidth - 17
  const desktopScreenWidth = screenWidth

  return (
    <div data-type='productCarouselWrapper'>
      <SATypography data-type='featured-collection-title' variant={SATypographyVariant.H3}>
        Featured Collections
      </SATypography>
      <Carousel
        onNext={next}
        onPrev={prev}
        index={safeIndex}
        setIndex={safeSetIndex}
        controls
        transition
        screenWidth={isOnMobile ? mobileScreenWidth : desktopScreenWidth}
        carouselName='productCarousel'
      >
        {List.map((slide, key) => {
          if (screenWidth >= 1200) {
            const isActive = index === key
            const { jpgLarge, title, collectionUrl, description } = slide
            return (
              <div
                key={key}
                data-type='slide'
                data-status={isActive ? 'slideActive' : 'slideInactive'}
              >
                <div data-type='pane'>
                  <picture data-type='image'>
                    <img
                      className='lazyload'
                      loading='lazy'
                      width='660'
                      height='700'
                      data-src={jpgLarge}
                      alt={`Slide ${key + 1}`}
                      itemProp='image'
                      data-type='pane-image'
                    />
                  </picture>
                  <noscript>
                    <img
                      width='660'
                      height='700'
                      className='lazyload'
                      loading='lazy'
                      data-src={jpgLarge}
                      alt={`Slide ${key + 1}`}
                      itemProp='image'
                      data-type='pane-image'
                    />
                  </noscript>

                  <div data-type='pane-content'>
                    <SATypography data-type='pane-title' variant={SATypographyVariant.H6}>
                      {title}
                    </SATypography>
                    <SATypography data-type='pane-description' variant={SATypographyVariant.SMALL}>
                      {description}
                    </SATypography>
                    <SALink href={collectionUrl} data-type='pane-link'>
                      <SATypography
                        variant={isMobile ? SATypographyVariant.CTA_SMALL : SATypographyVariant.CTA_MEDIUM}
                      >
                        Shop The Collection
                      </SATypography>
                    </SALink>
                  </div>
                </div>
                <div data-type='pane'>
                  <picture data-type='image'>
                    <img
                      className='lazyload'
                      loading='lazy'
                      width='660'
                      height='700'
                      data-src={rightArray[key].jpgLarge}
                      alt={`Slide ${key + 1}`}
                      itemProp='image'
                      data-type='pane-image'
                    />
                  </picture>
                  <noscript>
                    <img
                      width='660'
                      height='700'
                      src={rightArray[key].jpgLarge}
                      alt={`Slide ${key + 1}`}
                      itemProp='image'
                      data-type='pane-image'
                    />
                  </noscript>

                  <div data-type='pane-content'>
                    <SATypography data-type='pane-title' variant={SATypographyVariant.H6}>
                      {rightArray[key].title}
                    </SATypography>
                    <SATypography data-type='pane-description' variant={SATypographyVariant.SMALL}>
                      {rightArray[key].description}
                    </SATypography>
                    <SALink href={rightArray[key].collectionUrl} data-type='pane-link'>
                      <SATypography variant={isMobile ? SATypographyVariant.CTA_SMALL : SATypographyVariant.CTA_MEDIUM}>
                        Shop The Collection
                      </SATypography>
                    </SALink>
                  </div>
                </div>
              </div>
            )
          } else {
            const { jpgSmall, title, collectionUrl, description } = slide
            const isActive = safeIndex === key
            return (
              <div
                key={key}
                data-item={`${key}`}
                data-type='slide'
                data-status={isActive ? 'slideActive' : 'slideInactive'}
              >
                <div data-type='pane'>
                  <picture data-type='image'>
                    <source type='image/jpg' media='(max-width:980px)' srcSet={jpgSmall} />
                    <img
                      width='382'
                      height='272'
                      className='lazyload'
                      loading='lazy'
                      data-src={jpgSmall}
                      alt={`Slide ${key + 1}`}
                      itemProp='image'
                      data-type='pane-image'
                    />
                  </picture>
                  <noscript>
                    <img
                      width='382'
                      height='272'
                      className='lazyload'
                      loading='lazy'
                      data-src={jpgSmall}
                      alt={`Slide ${key + 1}`}
                      itemProp='image'
                      data-type='pane-image'
                    />
                  </noscript>
                  <div data-type='pane-content'>
                    <SATypography data-type='pane-title' variant={SATypographyVariant.H6}>
                      {title}
                    </SATypography>
                    <SATypography data-type='pane-description' variant={SATypographyVariant.SMALL}>
                      {description}
                    </SATypography>
                    <SALink href={collectionUrl} data-type='pane-link'>
                      <SATypography variant={isMobile ? SATypographyVariant.CTA_SMALL : SATypographyVariant.CTA_MEDIUM}>
                        Shop The Collection
                      </SATypography>
                    </SALink>
                  </div>
                </div>
              </div>
            )
          }
        })}
      </Carousel>
      <Dots
        data-name='productCarousel'
        index={safeIndex}
        length={List.length}
        setIndex={safeSetIndex} // Index set by onClick action
        navIndex={productIndex} // Index set by mobile drag action
      />
    </div>
  )
}

export default LargeCarouselConnector(CarouselComponent)